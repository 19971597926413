import { IS_DEVELOP, IS_LOCAL } from 'lib/constants/internals';
import { parseStringForURL } from 'lib/helpers/strings';
import { AppType } from './landing';

/**
 * Defines a record of keys to routes
 */
export type RouteRecord = Record<string, Route>;

/**
 * All routes are either raw strings, or functions that take a string key and produce a string.
 * If new types of routes exist that are other types of functions, etc, add them here. All items
 * need to eventually return a string.
 */
export type Route = string | ((key: string) => string);

/**
 * The app names to the production SK and SUP apps
 */
export const STOREFRONT_APP_NAME = 'canal-for-storefronts';
export const SUPPLIER_APP_NAME = 'canal-4';

/**
 * The paths to the SK and SUP apps inside of the Shopify admin.
 */
export const STOREFRONT_EMBEDDED_APP = `/admin/apps/${STOREFRONT_APP_NAME}`;
export const SUPPLIER_EMBEDDED_APP = `/admin/apps/${SUPPLIER_APP_NAME}`;

/**
 * Defines a record of a query parameter
 */
export type QueryParameterRecord = Record<string, string>;

/**
 * URL OVERRIDE used to inform useRouterQuery calls to ignore their inMemoryValues in favor
 * of whatever the new URL with the override is.
 * Use in case of bugs in which useRouterQuery prevents user from leaving their current page
 */
export const URL_OVERRIDE = '&override=true';

/*
 * Parameters used in the URL in order to filter the Discover results pages
 */
export const DISCOVER_FILTER_PARAMS = {
  filter: (value: string) => `dFilter=${value}`,
  alphanumeric: (value: string) => `alphanumeric=${value}`,
  sortBy: (value: string) => `dSortBy=${value.replace(/ /g, '_').toLowerCase()}`,
  partnerProducts: 'fromPartners=true',
  freeShipping: 'freeShipping=true',
  curationTag: (tag: string) => `curationTag=${tag}`,
};

/**
 * These map to the non-logged in routes in the app like privacy policy, support,
 * etc.
 */
export const LANDING_ROUTES = {
  /**
   * Main homepage!
   */
  home: '/',

  login: '/login',

  registration: '/onboarding/integration',

  accounts: '/accounts',

  appType: '/login/app-type',

  forgotPassword: '/login/forgot-password',

  reset: '/login/reset',

  userInvitation: '/login/user-invitation',

  /**
   * The main support email address as a mailto
   */
  supportEmail: 'support@shopcanal.com',
};

export const GONDOLIER_ONBOARDING_ROUTE = '/affiliate/outreach';

export const ONBOARDING_ROUTES = {
  api: {
    appType: '/onboarding/api/app-type',
    shopkeep: {
      account: '/onboarding/api/shopkeep/account',
      brandInformation: '/onboarding/api/shopkeep/brand-information',
      brandDetails: '/onboarding/api/shopkeep/brand-details',
      marketplaceTerms: '/onboarding/api/shopkeep/marketplace-terms',
      payments: '/onboarding/api/shopkeep/payments',
      shippingThreshold: '/onboarding/api/shopkeep/shipping-threshold',
      inviteTeam: '/onboarding/api/shopkeep/invite-team',
      congratulations: '/onboarding/api/shopkeep/congratulations',
    },
    supplier: {
      account: '/onboarding/api/supplier/account',
      brandInformation: '/onboarding/api/supplier/brand-information',
      brandDetails: '/onboarding/api/supplier/brand-details',
      dropshippingTerms: '/onboarding/api/supplier/dropshipping-terms',
      inviteTeam: '/onboarding/api/supplier/invite-team',
      congratulations: '/onboarding/api/supplier/congratulations',
    },
    gondolier: {
      account: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  bigcommerce: {
    appType: '/onboarding/bigcommerce/app-type',
    unavailable: '/onboarding/bigcommerce/unavailable',
    supplier: {
      account: '/onboarding/bigcommerce/supplier/account',
      brandInformation: '/onboarding/bigcommerce/supplier/brand-information',
      brandDetails: '/onboarding/bigcommerce/supplier/brand-details',
      dropshippingTerms: '/onboarding/bigcommerce/supplier/dropshipping-terms',
      productListings: '/onboarding/bigcommerce/supplier/product-listings',
      inviteTeam: '/onboarding/bigcommerce/supplier/invite-team',
      congratulations: '/onboarding/bigcommerce/supplier/congratulations',
    },
    gondolier: {
      account: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  sfcc: {
    appType: '/onboarding/sfcc/app-type',
    supplier: {
      account: '/onboarding/sfcc/supplier/account',
      location: '/onboarding/sfcc/supplier/location',
      brandDetails: '/onboarding/sfcc/supplier/brand-details',
      brandInformation: '/onboarding/sfcc/supplier/brand-information',
      dropshippingTerms: '/onboarding/sfcc/supplier/dropshipping-terms',
      sfccSetup: '/onboarding/sfcc/supplier/sfcc-setup',
      sfccFiles: '/onboarding/sfcc/supplier/sfcc-files',
      inviteTeam: '/onboarding/sfcc/supplier/invite-team',
      congratulations: '/onboarding/sfcc/supplier/congratulations',
    },
    unavailable: '/onboarding/sfcc/unavailable',
    gondolier: {
      account: '/affiliate/outreach',
      location: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      dropshippingTerms: '/affiliate/outreach',
      sfccSetup: '/affiliate/outreach',
      sfccFiles: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  csv: {
    appType: '/onboarding/csv/app-type',
    unavailable: '/onboarding/csv/unavailable',
    supplier: {
      account: '/onboarding/csv/supplier/account',
      location: '/onboarding/csv/supplier/location',
      brandInformation: '/onboarding/csv/supplier/brand-information',
      brandDetails: '/onboarding/csv/supplier/brand-details',
      dropshippingTerms: '/onboarding/csv/supplier/dropshipping-terms',
      inviteTeam: '/onboarding/csv/supplier/invite-team',
      congratulations: '/onboarding/csv/supplier/congratulations',
    },
    gondolier: {
      account: '/affiliate/outreach',
      location: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      dropshippingTerms: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  edi: {
    appType: '/onboarding/edi/app-type',
    unavailable: '/onboarding/edi/unavailable',
    supplier: {
      account: '/onboarding/edi/supplier/account',
      location: '/onboarding/edi/supplier/location',
      isa: '/onboarding/edi/supplier/isa',
      brandInformation: '/onboarding/edi/supplier/brand-information',
      brandDetails: '/onboarding/edi/supplier/brand-details',
      dropshippingTerms: '/onboarding/edi/supplier/dropshipping-terms',
      inviteTeam: '/onboarding/edi/supplier/invite-team',
      congratulations: '/onboarding/edi/supplier/congratulations',
    },
    gondolier: {
      account: '/affiliate/outreach',
      location: '/affiliate/outreach',
      isa: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      dropshippingTerms: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  shopify: {
    appType: '/onboarding/shopify/app-type',
    shopkeep: {
      account: '/onboarding/shopify/shopkeep/account',
      brandInformation: '/onboarding/shopify/shopkeep/brand-information',
      brandDetails: '/onboarding/shopify/shopkeep/brand-details',
      marketplaceTerms: '/onboarding/shopify/shopkeep/marketplace-terms',
      payments: '/onboarding/shopify/shopkeep/payments',
      shippingThreshold: '/onboarding/shopify/shopkeep/shipping-threshold',
      goToSupplierApp: '/onboarding/shopify/shopkeep/go-to-supplier-app',
      inviteTeam: '/onboarding/shopify/shopkeep/invite-team',
      congratulations: '/onboarding/shopify/shopkeep/congratulations',
    },
    supplier: {
      account: '/onboarding/shopify/supplier/account',
      brandInformation: '/onboarding/shopify/supplier/brand-information',
      brandDetails: '/onboarding/shopify/supplier/brand-details',
      preApprovalTerms: '/onboarding/shopify/supplier/pre-approval-terms',
      preApprovalProducts: '/onboarding/shopify/supplier/pre-approval-products',
      preApprovalComplete: '/onboarding/shopify/supplier/pre-approval-complete',
      productListings: '/onboarding/shopify/supplier/product-listings',
      dropshippingTerms: '/onboarding/shopify/supplier/dropshipping-terms',
      inviteTeam: '/onboarding/shopify/supplier/invite-team',
      congratulations: '/onboarding/shopify/supplier/congratulations',
    },
    gondolier: {
      account: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      marketplaceTerms: '/affiliate/outreach',
      payments: '/affiliate/outreach',
      shippingThreshold: '/affiliate/outreach',
      goToSupplierApp: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  flip: {
    terms: '/onboarding/with-flip/terms',
    complete: '/onboarding/with-flip/complete',
  },
  woocommerce: {
    appType: '/onboarding/woocommerce/app-type',
    shopkeep: {
      account: '/onboarding/woocommerce/shopkeep/account',
      brandInformation: '/onboarding/woocommerce/shopkeep/brand-information',
      brandDetails: '/onboarding/woocommerce/shopkeep/brand-details',
      marketplaceTerms: '/onboarding/woocommerce/shopkeep/marketplace-terms',
      payments: '/onboarding/woocommerce/shopkeep/payments',
      shippingThreshold: '/onboarding/woocommerce/shopkeep/shipping-threshold',
      inviteTeam: '/onboarding/woocommerce/shopkeep/invite-team',
      congratulations: '/onboarding/woocommerce/shopkeep/congratulations',
    },
    supplier: {
      account: '/onboarding/woocommerce/supplier/account',
      brandInformation: '/onboarding/woocommerce/supplier/brand-information',
      brandDetails: '/onboarding/woocommerce/supplier/brand-details',
      preApprovalTerms: '/onboarding/woocommerce/supplier/pre-approval-terms',
      preApprovalProducts: '/onboarding/woocommerce/supplier/pre-approval-products',
      preApprovalComplete: '/onboarding/woocommerce/supplier/pre-approval-complete',
      productListings: '/onboarding/woocommerce/supplier/product-listings',
      dropshippingTerms: '/onboarding/woocommerce/supplier/dropshipping-terms',
      inviteTeam: '/onboarding/woocommerce/supplier/invite-team',
      congratulations: '/onboarding/woocommerce/supplier/congratulations',
    },
    gondolier: {
      account: '/affiliate/outreach',
      brandInformation: '/affiliate/outreach',
      brandDetails: '/affiliate/outreach',
      marketplaceTerms: '/affiliate/outreach',
      payments: '/affiliate/outreach',
      shippingThreshold: '/affiliate/outreach',
      inviteTeam: '/affiliate/outreach',
      congratulations: '/affiliate/outreach',
    },
  },
  integration: '/onboarding/integration',
};

/**
 * Page that Shopify sends users to for installing the app or opening it inside
 * of the admin. We also send users this way when they are missing scopes, so
 * they can re-auth.
 */
export const SHOPIFY_ENTRYPOINT = '/shopify-entrypoint';

export const AGNOSTIC_ROUTES = (type: AppType) => ({
  overview: type === 'gondolier' ? '/affiliate/outreach' : `/${type}/overview`,
  settings: `/${type}/settings`,
  discover: `/${type}/discover`,
  discoverMoments: `/${type}/discover/moments`,
  discoverShops: `/${type}/discover/shops`,
  discoverProducts: `/${type}/discover/products`,
  discoverExistingPartnerProducts: `/${type}/discover/products?${DISCOVER_FILTER_PARAMS.partnerProducts}`,
  discoverFreeShippingProducts: `/${type}/discover/products?${DISCOVER_FILTER_PARAMS.freeShipping}`,

  onboarding: `/${type}/onboarding`,
  onboardingType: (onboardingType: string): string =>
    `/${type}/onboarding/${onboardingType}/welcome`,

  opportunities: `/${type}/opportunities`,
  discoverPreview: `/${type}/discover/preview`,

  orders: `/${type}/orders`,
  orderDetails: (orderId: string): string => `/${type}/orders/${orderId}`,

  /**
   * Individual page to discover products in a shop and add to a request
   */
  discoverShop: (shopId: string): string => `/${type}/discover/${shopId}`,

  /**
   * Shops page with the filter query prepended
   * @param filter Name of the filter to prepend
   */
  discoverFilteredShops: (filter: string): string =>
    `/${type}/discover/shops?${DISCOVER_FILTER_PARAMS.filter(
      parseStringForURL(filter),
    )}${URL_OVERRIDE}`,

  /**
   * Shops page with the alphanumeric filter query prepended
   * @param valueName Name of the alphanumeric filter to prepend
   */
  discoverShopsAlphanumeric: (valueName: string): string =>
    `/${type}/discover/shops?${DISCOVER_FILTER_PARAMS.alphanumeric(valueName)}${URL_OVERRIDE}`,

  /**
   * Shops page with the alphanumeric filter query prepended
   * @param valueName Name of the alphanumeric filter to prepend
   */
  discoverShopsSortBy: (valueName: string): string =>
    `/${type}/discover/shops?${DISCOVER_FILTER_PARAMS.sortBy(valueName)}${URL_OVERRIDE}`,

  /**
   * Products page filter query prepended
   * @param filter Name of the filter to prepend
   */
  discoverFilteredProducts: (filter: string): string =>
    `/${type}/discover/products?${DISCOVER_FILTER_PARAMS.filter(
      parseStringForURL(filter),
    )}${URL_OVERRIDE}`,

  /**
   * Products page with the curationTag filter query prepended
   * @param curationTag Curation tag to prepend
   */
  discoverCurationTag: (curationTag: string): string => {
    const route = `/${type}/discover/products?${DISCOVER_FILTER_PARAMS.curationTag(
      parseStringForURL(curationTag),
    )}${URL_OVERRIDE}`;
    return route;
  },

  /**
   * Products page with the search query prepended
   * @param search Search query to search on
   * @param id Product ID clicked on from typeahead
   */
  discoverProductSearch: (search: string, id?: string): string =>
    `/${type}/discover/products?search=${search}${id ? `&id=${id}` : ''}${URL_OVERRIDE}&page=1`,

  discoverShopSearch: (search: string, id?: string): string =>
    `/${type}/discover/shops?search=${search}${id ? `&id=${id}` : ''}${URL_OVERRIDE}&page=1`,

  product: (productId: string): string => `/products/${productId}`,
  wishlists: `/${type}/wishlists`,
  wishlist: (wishlistId: string): string => `/${type}/wishlists/${wishlistId}`,

  proposals: `/${type}/proposals`,

  proposalView: (proposalId: string): string => `/${type}/proposals/${proposalId}`,
  proposalEdit: (proposalId: string): string => `/${type}/proposals/${proposalId}/edit`,
  proposalCounter: (proposalId: string): string => `/${type}/proposals/${proposalId}/counter`,

  sampleRequestView: (sampleRequestId: string): string =>
    `/${type}/sample-requests/${sampleRequestId}`,
  sampleRequestEdit: (sampleRequestId: string): string =>
    `/${type}/sample-requests/${sampleRequestId}/edit`,

  inbox: `/${type}/inbox`,
  inboxSelectedContainer: (containerId: string): string => `/${type}/inbox/${containerId}`,
  inboxSelectedBrands: (brandIds: string): string => `/${type}/inbox?brands=${brandIds}`,
  inboxSelectedCategory: (category: string): string => `/${type}/inbox?category=${category}`,
  renegotiateTerms: (search: string) => `/${type}/proposals/renegotiate-terms?${search}`,
  renegotiateCommission: (search: string) => `/${type}/proposals/renegotiate-commission?${search}`,

  settingsTab: (tabId: string): string => `/${type}/settings?tab=${tabId}`,
});

/**
 * These map to all the shopkeep pages in the app. Each have the same
 * prefix and represent all reachable shopkeep pages.
 */
export const SHOPKEEP_ROUTES = {
  ...AGNOSTIC_ROUTES('shopkeep'),

  index: '/shopkeep/',
  refer: '/shopkeep/refer',
  onboarding: '/shopkeep/onboarding',
  inventory: '/shopkeep/inventory',
  inventoryAlerts: '/shopkeep/inventory/alerts',
  preapprovedTabInventory: '/shopkeep/inventory?tab=pre-approved',
  discoverPreview: '/shopkeep/discover/preview',

  /**
   * Account settings for shopkeeps
   */
  settings: '/shopkeep/settings',

  /**
   * Displays a list of all Suppliers with whom the logged-in SK has
   * approved products with
   */
  suppliers: '/shopkeep/suppliers',
  supplierSelected: (supplierId: string): string => `/shopkeep/suppliers?selected=${supplierId}`,

  /**
   * Displays insights regarding a shopkeeps data
   */
  insights: '/shopkeep/insights',

  /**
   * Displays insights regarding orders and fulfillments
   */
  fulfillments: '/shopkeep/fulfillments',

  /**
   * Displays summary data about all payments
   */
  paymentsSummary: '/shopkeep/insights/payments',

  /**
   * Displays insights data about top products
   */
  insightsTopProducts: '/shopkeep/insights/products',

  /**
   * Displays insights data about top suppliers
   */
  insightsTopSuppliers: '/shopkeep/insights/suppliers',

  /**
   * Displays a detailed view of a single payment (CONNER - Deprecated until updated for Transactions V2)
   */
  // paymentDetails: (paymentId: string): string => `/shopkeep/insights/payments/${paymentId}`,

  /**
   * Page for resending expired proposals
   */
  proposalResend: (proposalId: string): string => `/shopkeep/proposals/${proposalId}/resend`,

  /**
   * Page for creating a sample request
   */
  createSampleRequest: (supplierId: string) => `/shopkeep/sample-requests/create/${supplierId}`,

  /**
   * Displays all draft proposals a logged-in shop has on their current device
   */
  cart: '/shopkeep/cart',

  /**
   * Page for all of the bundles a shopkeep has created
   */
  bundles: '/shopkeep/bundles',

  /**
   * Page for creating bundles
   */
  bundlesCreateAdvanced: '/shopkeep/tools/bundles/advanced',

  /**
   * Page for creating bundles
   */
  bundlesCreateSimple: '/shopkeep/tools/bundles/simple',

  /**
   * Page for creating bundles
   */
  bundlesSelect: '/shopkeep/tools/bundles/select',

  /**
   * Page for editing a bundle
   */
  bundlesEdit: (bundleId: string): string => `/shopkeep/tools/bundles/${bundleId}/edit`,

  viewPartnership: (supplierId: string): string =>
    `/shopkeep/suppliers?selected=${supplierId}&tab=partnership`,
};

/**
 * These map to every reachable supplier page in the app. Each have
 * the same prefix.
 */
export const SUPPLIER_ROUTES = {
  ...AGNOSTIC_ROUTES('supplier'),

  index: '/supplier/',
  discoverPreview: '/supplier/discover/preview',
  discoverProfileEdit: '/supplier/discover/preview?settings=true',
  settings: '/supplier/settings',

  insights: '/supplier/insights',
  insightsTopShopkeeps: '/supplier/insights/shopkeeps',
  listings: '/supplier/listings',
  listingsInventory: '/supplier/listings/inventory',
  refer: '/supplier/refer',
  onboarding: '/supplier/onboarding',
  featuredStorefronts: '/supplier/discover/featuredstorefronts',
  testOrder: '/supplier/test-order',

  /**
   * Displays summary data about all payments
   */
  payoutsSummary: '/supplier/payouts',

  /**
   * Displays a list of all Storefronts with whom the logged-in SUP has
   * approved products with
   */
  storefronts: '/supplier/storefronts',
  storefrontSelected: (storefrontId: string): string =>
    `/supplier/storefronts?selected=${storefrontId}`,

  initiatePartnership: (storefrontId: string): string =>
    `/supplier/storefronts/initiate/${storefrontId}`,

  editPartnership: (storefrontId: string): string => `/supplier/storefronts/edit/${storefrontId}`,

  viewPartnership: (storefrontId: string): string =>
    `/supplier/storefronts?selected=${storefrontId}&tab=partnership`,

  /**
   * The link to the supplier referal landing page
   */
  connect: '/supplier/connect',
};

export const GONDOLIER_ROUTES = {
  index: '/affiliate/',
  outreach: '/affiliate/outreach',
  campaigns: '/affiliate/campaigns',
  createCampaign: '/affiliate/campaigns/create',
  editCampaign: (campaignId: string) => `/affiliate/campaigns/edit/${campaignId}`,
  creators: '/affiliate/creators',
  insights: '/affiliate/insights',
  settings: '/affiliate/settings',
  signup: '/affiliate/signup',
  login: '/affiliate/login',
  payment: '/affiliate/payment',
};

/**
 * These are routes within our Spyglass (Internal Tools) app
 */
export const SPYGLASS_PREFIX = '/spyglass';
export const SPYGLASS_ROUTES = {
  index: SPYGLASS_PREFIX,

  selectedStore: (storeId: string): string => `${SPYGLASS_PREFIX}/${storeId}`,
};

/**
 * These are routes for the part of the app that redirects users to the
 * proposal page on the appropriate app based on their role.
 */
export const PROPOSAL_ROUTES = {
  selectedProposal: (proposalId: string): string => `/proposal/${proposalId}`,
  notFound: '/proposal/not-found',
};

export const SAMPLE_REQUEST_ROUTES = {
  selectedSampleRequest: (sampleRequestId: string): string => `/sample-request/${sampleRequestId}`,
  notFound: '/sample-request/not-found',
};

/**
 * ID for our Typeform that we send Canal-interested customers to
 */
export const TYPEFORM_ID = 'cKdR9k0T';

const APP_URL = 'https://app.shopcanal.com';

/**
 * Gets an environment-aware URL for the app; should work for all environments
 */
export const getEnvironmentAwareAppURL = (): string => {
  if (IS_LOCAL) return 'http://localhost:3000';
  if (IS_DEVELOP) return 'https://develop.shopcanal.com';
  return APP_URL;
};

/**
 * These link to Canal's presence on the internet beyond this site
 */
export const EXTERNAL_URLS = {
  CALENDLY_CALL_KAILI: 'https://calendly.com/kaili-canal/30min',
  WEBSITE: 'https://shopcanal.com',
  BOOK_A_DEMO: 'https://shopcanal.com/book-demo',
  CONNECT_WITH_CANAL: 'https://calendly.com/shopcanal/connect-with-canal',
  CANAL_FOR_WOOCOMMERCE: 'https://shopcanal.com/canal-for-woocommerce',
  CANAL_WOOCOMMERCE_APP: 'https://woo.com/products/canal-for-woocommerce/',
  CANAL_BIGCOMMERCE_APP: 'https://apps.bigcommerce.com/details/47304',
  INSPIRATION: 'https://shopcanal.com/inspiration',
  REQUEST_INVITATION: 'https://shopcanal.com/get-started',
  TERMS_OF_SERVICE: 'https://shopcanal.com/terms-of-service',
  PRIVACY_POLICY: 'https://shopcanal.com/privacy-policy',
  APP: APP_URL,
  SUPPLIER_APP_URL: 'https://apps.shopify.com/canal-for-suppliers',
  STOREFRONT_APP_URL: 'https://apps.shopify.com/canal-for-storefronts',
  TWITTER: 'https://twitter.com/canal',
  LINKEDIN: 'https://www.linkedin.com/company/shopcanal',
  LEVER: 'https://jobs.lever.co/shop-canal',
  INSTAGRAM: 'https://www.instagram.com/shop.canal',
  TYPEFORM: `https://8dwzaidhebp.typeform.com/to/${TYPEFORM_ID}`,
  FEEDBACK_ASANA_FORM: 'https://form.asana.com?k=ZJZ2VpGVKmafm1ZSbF71jQ&d=1199691950954316',
  BLOG: 'https://shopcanal.com/blog',
  BLOG_CURATION: 'https://shopcanal.com/blog/curation',
  CURATION_REQUEST_FORM: 'https://form.asana.com/?k=_8NnUN9X0TgepNTQIxzKlw&d=1199691950954316',
  API_DOCS: 'https://docs.shopcanal.com',
  REWARDS_STORE: 'https://canal-supplier.myshopify.com/',
  STRIPE_DASHBOARD: 'https://dashboard.stripe.com/',
  MAGICOS_DASHBOARD: 'https://magicos.flip.shop/pages/revenue',
  FAQ: 'https://shopcanalhelp.zendesk.com/hc/en-us',
  FAQ_GENERAL: 'https://shopcanalhelp.zendesk.com/hc/en-us/categories/14487801541275-About-Canal',
  FAQ_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/categories/14450647751451-Canal-for-Suppliers',
  FAQ_SHOPKEEPS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/categories/7021087115291-Canal-for-Storefronts',
  FAQ_PAYMENTS_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14747794044443-Getting-Paid-',
  FAQ_PAYMENTS_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14475270996507-Getting-Paid',
  FAQ_ARTICLE_PROPOSALS_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14754144704155-Drafting-a-proposal',
  FAQ_ARTICLE_PROPOSALS_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14458328623771-How-Proposals-work',
  FAQ_ARTICLE_INVITE_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14749244624923-Referring-a-Brand',
  FAQ_ARTICLE_SUPPLIERS_PROPOSALS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14458328623771-How-Proposals-work',
  FAQ_INVENTORY_BUFFER:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/19897226267675-Inventory-Threshold-Buffer',
  FAQ_BUNDLES:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14749886161819-Utilizing-Product-Bundles',
  FAQ_BUNDLES_CREATION:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14749886161819-Utilizing-Product-Bundles',
  FAQ_BUY_BUTTON:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14742667978011-Utilizing-Canal-s-Buy-Button',
  FAQ_ONBOARDING_WITH_SFCC:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/23369657401883-Onboarding-with-Salesforce-CommerceCloud',
  FAQ_SETUP_STRIPE_FOR_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14459623642395-Setting-up-Stripe',
  FAQ_SUP_INVENTORY:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14457364308251-Adding-or-removing-products-from-Canal',
  FAQ_ARTICLE_SK_PAYMENTS_TO_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14747794044443-Getting-Paid-',
  FAQ_SHIPPING_FOR_STOREFRONTS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14755183867419-What-are-the-shipping-terms-I-can-choose-on-Canal-',
  FAQ_SHIPPING_FOR_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/18270128759707-What-are-the-shipping-terms-I-can-choose-on-Canal-',
  FAQ_ARTICLE_SK_IMPORT_NEW_PRODUCTS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14974627822235-Adding-Supplier-products-to-your-store',
  FAQ_SHIPPING_SHOPIFY_PLAN:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14753445170075-Setting-up-shipping-based-on-your-Shopify-plan',
  FAQ_WAIVED_FEES:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14749244624923-Referring-a-Brand',
  FAQ_HOW_DO_I_INVITE_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14749244624923-Referring-a-Brand',
  FAQ_BEST_PRACTICES_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/sections/14450756856859-Best-Practices-FAQ',
  FAQ_BEST_PRACTICES_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/sections/14450815937819-Best-Practices-FAQ',

  FAQ_TRANSACTION_FEE:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14487997277083-What-does-it-cost-to-use-Canal-',
  FAQ_DISCOVER:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14959232977307-What-is-Discover-',
  FAQ_LISTING_SUP_PRODUCTS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14457364308251-Adding-or-removing-products-from-Canal',
  FAQ_ORDER_FULFILLMENT_PROCESSING_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14476566179227-Order-Fulfillment-',
  FAQ_ORDER_FULFILLMENT_PROCESSING_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14476874738203-Order-fulfillment-',

  FAQ_SHIPPING_AND_FULFILLMENT:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14476874738203-Order-fulfillment-',
  FAQ_SUP_ONBOARDING:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14451075986203-Onboarding-to-Canal-as-a-Supplier',
  FAQ_ARTICLE_COMMISSIONS_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14747794044443-Getting-Paid-',
  FAQ_ARTICLE_COMMISSIONS_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14475270996507-Getting-Paid',
  FAQ_RENEGOTIATING_COMMISSION:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14754443664539-Renegotiating-commission',
  FAQ_PROPOSALS_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14754144704155-Drafting-a-proposal',
  FAQ_CANAL_COST:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14487997277083-What-does-it-cost-to-use-Canal-',
  FAQ_ARTICLE_BEGIN_WITH_MARKET_PRODUCTS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14755655184411-Product-promotions-and-placement',

  FAQ_ARTICLE_ORDER_FORWARDING_DELAY:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14486426430235-Order-forwarding-delay-',
  FAQ_FREE_SHIPPING:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14992447223323-Find-products-that-ship-free',
  FAQ_WHAT_ARE_STOREFRONTS_AND_SUPPLIERS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14487991198875-What-is-Canal-',
  FAQ_GIVE_100_GET_100:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14749244624923-Referring-a-Brand',
  FAQ_PLACE_AND_PROMOTE_PRODUCTS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14755655184411-Product-promotions-and-placement',
  FAQ_WHEN_DO_PAYOUTS_APPEAR:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14459987956507-Accessing-payout-information',
  HELP_CENTER_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/categories/14450647751451-Canal-for-Suppliers',
  FAQ_GETTING_PAID_AS_A_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14475270996507-Getting-Paid-as-a-Supplier',
  FAQ_POST_PURCHASE_UPSELL:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14745707493147-Using-the-Post-Purchase-Page',
  FAQ_TEST_ORDER:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14982283396507-How-to-create-a-test-order',
  FAQ_RUNNING_TEST_ORDER:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/15419155161755-Running-a-test-order',
  FAQ_APPLY_DISCOUNT:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14753704012827-How-do-discounts-work-for-Canal-products-',
  FAQ_SUPPLIER_INVENTORY_ALLOCATION:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/29099740562587-Canal-Inventory-Allocation',
  FAQ_SUPPLIER_INVENTORY_TRACKED:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14452702232475-How-is-Supplier-inventory-tracked',
  FAQ_ACCOUNT_SETTINGS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/15272305227547-Account-Settings',
  FAQ_INVENTORY_ALERTS: 'https://shopcanalhelp.zendesk.com/hc/en-us/articles/17131960434587',

  FAQ_SHOPKEEP_PROPOSAL_STATUSES:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/17017287478939-Proposal-Statuses',
  FAQ_SUPPLIER_PROPOSAL_STATUSES:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/17131273914011-Proposal-Statuses',
  FAQ_IMPORT_PRODUCTS: 'https://shopcanalhelp.zendesk.com/hc/en-us/articles/19464680210587',
  FAQ_SUP_SHIPPING_TITLES: 'https://shopcanalhelp.zendesk.com/hc/en-us/articles/19467663546139',
  FAQ_HOW_PREAPPROVE_WORKS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/14739374174619-How-Pre-Approved-works',
  FAQ_SALE_PRICE_SYNCING:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/17313582932379-Passing-discounts-onto-Storefront-partners',
  FAQ_IMPORT_TRACKING:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/19464680210587-Importing-and-exporting-products-via-CSV',
  FAQ_STOREFRONT_PARTNER_DESIRED_TERMS:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/23082514621595-Storefront-partners-desired-terms',
  FAQ_CERTIFIED_RESELLER:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/26340670821275-Being-a-Certified-Reseller',
  FAQ_RETAILER_EDITOR_PERMISSION_FOR_SUP:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/28408270125595-Enabling-Retailer-editor-permissions',
  FAQ_RETAILER_EDITOR_PERMISSION_FOR_SK:
    'https://shopcanalhelp.zendesk.com/hc/en-us/articles/28408342809371-Product-editing-permissions',
};

/**
 * These are jump links for the homepage. Each link corresponds to an
 * id on a component that gets scrolled into view when the link is clicked.
 */
export const JUMP_LINK_IDS = {
  HOW_IT_WORKS: 'how-it-works',
  FOR_STOREFRONTS: 'for_storefronts',
  FOR_SUPPLIERS: 'for_suppliers',
  TEAM: 'team',
  INVESTORS: 'investors',
  FAQ: 'faq',
  OPEN_POSITIONS: 'open_positions',
  INSPIRATION: 'inspiration',
};

/**
 * Builds a link that's jumpable to a section on the homepage.
 *
 * @param jumpLink Link to jump to
 * @returns String that is meant to be used as a jump link
 */
export const jumpLink = (link: string): string => `/#${link}`;

export const ZENDESK_KEY = 'c4e75871-032d-4198-9139-e9b23b43e0db';

/**
 * Supplier settings tab keys
 */
export enum SupplierSettingsTabIds {
  BRAND_INFO_TAB = 'brandInfo',
  TEAM_TAB = 'team',
  PREFERENCES_TAB = 'preferences',
  YOUR_ACCOUNT_TAB = 'yourAccount',
  DEVELOPER_TAB = 'developer',
  BILLING_TAB = 'billing',
}

/**
 * Supplier settings anchor tags for utilizing jump links
 */
export const SUPPLIER_SETTINGS_ANCHOR_TAGS = {
  VISIBILITY: 'visibility',
};
