export const FLIP_MAGIC_OS_REFERRAL_IDS = [
  'flip-0648cc0ea21d4d3e::sk', // The first one
  'curio-collectibles-11568b8c9a544096::sk', // This is pet pro (Canal's test store on prod)
  'FlipStorefront-7136a2aadd6f47a3::sk', // This is the one being used in the test links we received
];
export const FLIP_MAGIC_OS_REMOTE_RIDS = [
  '34179258', // The first one
  '50919669919', // This is pet pro (Canal's test store on prod)
  '2207315632', // This is the one being used in the test links we received
];

// Arrays of Flip's remote rids and ids. They have multiple stores, so we need to check for all of them.
// Each index of the array corresponds to the same index in the other array.
export const FLIP_SK_REMOTE_RIDS = ['2207315632', '78166589720', ...FLIP_MAGIC_OS_REMOTE_RIDS];

export const FLIP_API_ACCOUNT_ID = 'f876bf3c-87ba-48f6-b7ef-e2d8020988ba';
export const FLIP_SK_IDS = [
  '080d1ace-376b-471b-a5fb-c8448390f6b0',
  '8a3e6506-b1f2-4eec-94a4-8547e4079127',
  FLIP_API_ACCOUNT_ID,
];

export const FELLOW_SK_REMOTE_RIDS = ['5762351219', '65967358183'];
export const FELLOW_SK_IDS = [
  '4ea255f6-6ea2-49fd-8a32-e661305a4990',
  'd465d524-9d91-49eb-acf2-9465b3166259',
];

export const HERSTORY_SK_REMOTE_RIDS = ['55424876724', '81918558519'];
